:root {
  --color-primary-700: #14161F;
  --color-primary: #5DC6D4;
  --color-primary-400: #9EA1B0;
  --color-primary-300: #F2F4F7;
  --color-primary-200: #DBDDE5;
  --color-primary-100: #EDEEF2;

  --color-secondary-700: #49318B;
  --color-secondary: #7855D7;
  --color-secondary-400: #9274E2;
  --color-secondary-200: #C7B7F3;
  --color-secondary-100: #E3DAFA;
  --color-secondary-landing-card: #B39EEA;

  --color-highlight-700: #086D65;
  --color-highlight-500: #14A69B;
  --color-highlight: #14A69B;
  --color-highlight-100: #CDF0EE;

  --color-neutral-black: #14161F;
  --color-neutral-700: #252833;
  --color-neutral-600: #404554;
  --color-neutral-500: #5E6475;
  --color-neutral-400: #9EA1B0;
  --color-neutral-white: #FFFFFF;
  --color-neutral-50: #F2F4F7;
  --color-neutral-100: #EDEEF2;
  --color-neutral-200: #DBDDE5;
  --color-neutral-300: #BEC0CC;
  --color-nav-font: #282A39;

  --color-positive-700: #047A0D;
  --color-positive: #70BA10;
  --color-positive-400: #8DCB3C;
  --color-positive-300: #AADB6B;
  --color-positive-100: #E3F5CC;

  --color-warning-700: #936102;
  --color-warning: #FFBD07;
  --color-warning-500: #FFBD07;
  --color-warning-400: #FFCD34;
  --color-warning-300: #FFDB66;
  --color-warning-100: #FFF4CC;

  --color-danger-700: #8F1512;
  --color-danger: #DB2A26;
  --color-danger-400: #E6564E;
  --color-danger-300: #EF8277;
  --color-danger-100: #FBD7D0;

  --color-info-700: #001F6E;
  --color-info: #0043CE;
  --color-info-400: #3367DC;
  --color-info-300: #668CE8;
  --color-info-100: #CCD8F9;

  --color-select-title: #5F6F7B;
  --color-modal-date: #686868;
}