@use './colors.scss';

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;600;700;900&display=swap");

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import 'nebular-icons/css/nebular-icons.css';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

* {
  font-family: 'Lexend', sans-serif;
  box-sizing: border-box;

  scroll-behavior: smooth !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lexend';
  color: var(--color-neutral-700);
}

p {
  font-family: 'Lexend';
}

.mat-cell,
.mat-header-cell {
  padding: 1rem .5rem !important;
}

.multi-select-max-height {
  ::ng-deep .ng-value-container {
    max-height: 2.2rem;
    height: 2.2rem;
    overflow: hidden auto;
    margin-right: 0.5rem;
  }
}

@keyframes fadeIn {
  0% {
    filter: opacity(0);
  }

  100% {
    filter: opacity(1);
  }
}

@keyframes fadeOut {
  0% {
    filter: opacity(1);
  }

  100% {
    filter: opacity(0);
  }
}